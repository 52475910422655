import { AvatarBadge } from '@playbooks/interface/avatars';
import { BorderBtn } from '@playbooks/interface/buttons';
import { DropToggle } from '@playbooks/interface/drops';
import { Div } from '@playbooks/interface/html';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';

export const FilterBtn = ({ type = 'icon', count, active, onClick }) => {
	const props = { count, active, onClick };

	// Render
	switch (type) {
		case 'drop':
			return <DropFilterBtn {...props} />;
		case 'icon':
			return <IconFilterBtn {...props} />;
	}
};

const DropFilterBtn = ({ count, active, onClick }) => (
	<Div display='inline-block' width='w-full'>
		{count > 0 && (
			<Div position='absolute' inset='-top-2.5 -right-2.5' zIndex='z-10'>
				<AvatarBadge>{count}</AvatarBadge>
			</Div>
		)}
		<DropToggle variant='border' display='flex-between' active={active} onClick={onClick} className='w-full'>
			<FarIcon icon='filter' />
			<Span>Filters</Span>
		</DropToggle>
	</Div>
);

const IconFilterBtn = ({ count, onClick }) => (
	<Div display='inline-block'>
		{count > 0 && (
			<Div position='absolute' inset='-top-2.5 -right-2.5' zIndex='z-10'>
				<AvatarBadge>{count}</AvatarBadge>
			</Div>
		)}
		<BorderBtn size='icon' icon='filter' onClick={onClick} />
	</Div>
);
