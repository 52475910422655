import { Fragment, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Drop, DropMenu } from '@playbooks/interface/drops';
import { Span } from '@playbooks/interface/html';
import { useTheme } from 'contexts';

const Emoji = dynamic(() => import('components/wrappers/emoji-picker-wrapper').then(v => v.EmojiWrapper), {
	ssr: false,
});
const EmojiPicker = dynamic(() => import('components/wrappers/emoji-picker-wrapper').then(v => v.EmojiPickerWrapper), {
	ssr: false,
});

const ReactionBtn = ({ reactions, taskRunning, onReact }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	// Computed
	const count = useMemo(() => {
		return reactions?.filter((v, i) => v.unified);
	}, [reactions]);

	// Methods
	const onModify = unified => {
		console.log('unified: ', unified);
		onReact({ reaction: unified });
	};

	const onToggle = () => setOpen(!open);

	const onClick = data => {
		console.log('data: ', data);
		onReact({ reaction: data.unified });
		setOpen(false);
	};

	// Render
	return (
		<Fragment>
			{Object.keys(reactions || {}).map((keyName, i) => {
				const reaction = reactions[keyName] || {};
				return (
					<BorderBtn size='xs' taskRunning={taskRunning} onClick={onModify}>
						<Emoji unified={reaction.reaction} />
						<Span>{reaction.count}</Span>
					</BorderBtn>
				);
			})}
			<Drop id='reaction_drop' open={open} setOpen={onToggle}>
				<BorderBtn size='xs' icon='face-smile' taskRunning={taskRunning} onClick={onToggle} />
				<DropMenu open={open} inset='left-0'>
					<EmojiPicker
						open={open}
						theme={theme.isDark ? 'dark' : 'light'}
						reactions={[['1F600', '1F973', '1F44C', '1F44D', '1F44E']]}
						onClick={onClick}
					/>
				</DropMenu>
			</Drop>
		</Fragment>
	);
};

export { ReactionBtn };
