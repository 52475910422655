import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { DropToggle } from '@playbooks/interface/drops';

const ActionsBtn = ({ type = 'detail', onToggle }) => {
	// Render
	return (
		<Fragment>
			{type === 'detail' ? (
				<DropToggle variant='primary' onClick={onToggle}>
					Actions
				</DropToggle>
			) : (
				<BorderBtn size='icon' icon='chevron-down' onClick={onToggle} />
			)}
		</Fragment>
	);
};

export { ActionsBtn };
