import { Span } from '@playbooks/interface/html';
import { client, formatOptions } from 'api';
import { AuthBtn } from 'components/auth/auth-btn';
import { useStore } from 'contexts';
import { CodeSandboxBodyDecorator, CodeSandboxFilesDecorator } from 'decorators';
import { useAction } from 'hooks';
import { Skeleton } from 'molecules/skeletons';
import { log } from 'utils';

const CodesandboxBtn = ({ model, rootApi, loading }) => {
	const store = useStore();

	// Actions
	const [onCode, codeTask] = useAction({}, async () => {
		const response = await store.query({ url: `${rootApi}/tree` });
		const url = `https://codesandbox.io/api/v1/sandboxes/define?json=1`;
		const files = CodeSandboxFilesDecorator(response.data);
		const body = CodeSandboxBodyDecorator(files);
		const options = formatOptions('POST', { accept: 'application/json' }, body);
		log('codesandbox: ', { url, options, files });
		const data = await client(url, options);
		log('codesandbox: ', { data });
		window.open(`https://codesandbox.io/s/${data.sandbox_id}`, '_blank');
	});

	// Render
	return (
		<AuthBtn
			type='accent'
			size='icon'
			icon='code-simple'
			disabled={loading || !model.playgrounds?.showCodesandbox}
			taskRunning={codeTask.running}
			onClick={onCode}>
			{loading ? <Skeleton className='w-20' /> : <Span>Codesandbox</Span>}
		</AuthBtn>
	);
};

export { CodesandboxBtn };

// Docs
// https://codesandbox.io/docs/learn/sandboxes/cli-api
